import React from 'react'

class DatabaseConfig extends React.Component {
    constructor(props) {
        super(props)
        if (props.project.database === undefined) {
            props.project.database = {
                name: "",
                host: "",
                port: "",
                user:"",
                password:"",
                driver:"",
                version:""
            }
        }
        this.state = {            
            project: props.project,
        }
    }

    render() {
        return (
            <div>
                <b  style={{ fontSize: "20px" }}>Database</b>
                <br /><br />
                <div style={{ display: "block", padding: "5px" }}>
                    <span className='inputLabel'>Name</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.database.name = e.target.value
                                return currState
                            })                           
                        }}
                        type="text"
                        id="name"
                        name="name"
                        value={this.state.project?.database?.name}
                        required />
                    <br />
                    <span className='inputLabel'>Host</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.database.host = e.target.value
                                return currState
                            })                            
                        }}
                        type="text"
                        id="host"
                        name="host"
                        value={this.state.project?.database?.host}
                        required />
                    <br />
                    <span className='inputLabel'>Port</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.database.port = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="port"
                        name="port"
                        value={this.state.project?.database?.port}
                        required />
                    <br />
                    <span className='inputLabel'>User</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.database.user = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="user"
                        name="user"
                        value={this.state.project?.database?.user}
                        required />
                    <br />
                    <span className='inputLabel'>Password</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.database.password = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="password"
                        name="password"
                        value={this.state.project?.database?.password}
                        required />
                    <br />
                    <span className='inputLabel'>Driver</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.database.driver = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="driver"
                        name="driver"
                        value={this.state.project?.database?.driver}
                        required />
                    <br />
                    <span className='inputLabel'>Version</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.database.version = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="version"
                        name="version"
                        value={this.state.project?.database?.version}
                        required />
                    <br />
                </div>
            </div>
        )
    }
}

export default DatabaseConfig