import React from 'react'
import EntityCard from './entityCard'
import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
import EntityEditor from './entityEditor';
import LineTo from 'react-lineto';
import Modal from './modal';
import { v4 as uuid } from 'uuid';




const canvasSize = 3000

const createShadow = (size) => `${size}px ${size}px 15px #ccc inset`
const shadowSize = 10

var stringToColour = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var j = 0; j < 3; j++) {
        var value = (hash >> (j * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

var project = null
class Project extends React.Component {

    constructor(props) {
        super(props)
        var existingProject = localStorage.getItem('project')
        this.state = {
            project: existingProject !== null ? JSON.parse(existingProject) : { entities: [] },
            selectedEntity: {},
            entityEditorOpen: false,
            modals: new Map(),
            modalsOrder: [],
            canvas: {
                overflow: "scroll",
                scale: 1,
                clientX: 0,
                clientY: 0,
                translateX: 0,
                translateY: 0
            }
        }
        project = this
    }

    escFunction(event) {
        if (event.key === "Escape") {
            if (project.state.modalsOrder.length > 0) {
                var currModal = project.state.modals.get(project.state.modalsOrder[project.state.modalsOrder.length - 1])
                currModal.reactRef.current.close()
            } else if (project.state.entityEditorOpen) {
                project.closeEntityEditor()
            }
        }
    }


    componentDidMount() {
        this.refreshInterval = setInterval(() => {
            localStorage.setItem('project', JSON.stringify(this.state.project))
        }, 100);

        document.addEventListener("keydown", this.escFunction, false);
        // this is needed to update the relationships after loading
        this.setState((currState) => {
            return currState
        })
    }
    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        document.removeEventListener("keydown", this.escFunction, false);
    }

    import(e) {
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            var project = JSON.parse(e.target.result);
            localStorage.setItem('project', e.target.result)
            this.setState((currState) => {
                currState.project = project
                return currState
            })
        };
    }

    export() {
        const { project } = this.state;

        // create file in browser
        const fileName = "gpg_project_" + project.identifier + "_" + Date.now();
        const json = JSON.stringify(project);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    openEntityEditor(entity) {
        this.setState({ ...this.state, selectedEntity: entity, entityEditorOpen: true })
    }

    closeEntityEditor() {
        this.setState({ ...this.state, entityEditorOpen: false })
    }

    openModal(contentType, contentProps) {
        this.setState((cs) => {
            var nmid = uuid()
            var ref = React.createRef();
            var nm = {
                id: nmid,
                contentType: contentType,
                contentProps: contentProps,
                open: true,
                reactRef: ref,
                count: cs.modalsOrder.length + 1,
                onClose: (m) => {
                    setTimeout(() => {
                        this.setState((cs) => {
                            var pid = m.state.id
                            const index = cs.modalsOrder.indexOf(pid);
                            cs.modalsOrder.splice(index, 1)
                            cs.modals.delete(pid)
                            return cs
                        })
                    }, 200)
                },
            }
            cs.modals.set(nmid, nm)
            cs.modalsOrder.push(nmid)
            return cs
        })
    }

    render() {
        var project = this
        return (
            <div style={{ display: "block", minWidth: "100%", height: "100%" }}>
                <div style={{ position: "absolute", zIndex: 100, backgroundColor: "#049aff", minWidth: "100%", display: "block", textAlign: "right" }}>
                    <span style={{ color: "#FFF", fontSize: "20px", fontWeight: "bold", float: "left", padding: "8px" }}>GPG <span style={{ fontWeight: "150" }}>| Editor</span></span>

                    <span className='headerButton'
                        onClick={(e) => {
                            if (this.state.project.render === undefined) {
                                this.setState({ ...this.state, project: { ...this.state.project, render: {} } })
                            }
                            this.openModal("fieldRender", { ...this.state, type: "project", render: this.state.project.render })
                        }}
                    >
                        Project Settings: &nbsp;
                        <b>{this.state.project.identifier}</b>
                    </span>

                    <label className='headerButton' htmlFor="import"
                    >Import Project</label>
                    <input id="import" type="file" onChange={(e) => this.import(e)} hidden />
                    <span className='headerButton'
                        onClick={() => {
                            this.export()
                        }}
                    >Export</span>
                    <span className='headerButton'
                        onClick={() => {
                            var ne = JSON.parse(newEntity)
                            ne.identifier = "new_entity"
                            this.state.project.entities.push(ne)
                            this.setState(this.state)
                        }}
                    >Add Entity</span>
                </div>
                <div
                    id="scrollable-canvas"
                    style={{
                        width: '100vw',
                        height: '100vh',
                        overflow: this.state.canvas.overflow,
                    }}
                >
                    <div id="scrollable-canvas-container"
                        style={{
                            border: '4px solid #46b1f0',
                            height: canvasSize,
                            width: canvasSize,
                            boxShadow: `${createShadow(shadowSize)}, ${createShadow(-shadowSize)}`,
                            transform: `scale(${this.state.canvas.scale}, ${this.state.canvas.scale}) translate(${this.state.canvas.translateX}px, ${this.state.canvas.translateY}px)`,
                            transformOrigin: '0 0',
                        }}

                        onWheel={e => {
                            this.setState(this.state)
                        }}

                    >
                        <div className='entity-container' id="entity-container" style={{ margin: 0, padding: 0, display: "inline-block", minWidth: "100%", height: "100%", overflow: "scroll" }}>
                            {
                                this.state.project.entities.map((e) => {
                                    return e.fields.map((f, i) => {
                                        if (f.entity_ref !== undefined && f.entity_ref !== "") {
                                            return <LineTo
                                                key={"gpg_" + e.identifier + "_" + f.entity_ref + "_" + i}
                                                within='entity-container'
                                                delay={true}
                                                from={e.identifier}
                                                to={f.entity_ref}
                                                borderWidth={4}
                                                className='entity-relationship'
                                                borderColor={stringToColour("gpg_" + e.identifier + "_" + f.entity_ref)} />
                                        }
                                        if (f.type ==="array" && f.array_config.entity_ref !== undefined && f.array_config.entity_ref !== "") {
                                            return <LineTo
                                                key={"gpg_" + e.identifier + "_" + f.array_config.entity_ref + "_" + i}
                                                within='entity-container'
                                                delay={true}
                                                from={e.identifier}
                                                to={f.array_config.entity_ref}
                                                borderWidth={4}
                                                className='entity-relationship'
                                                borderColor={stringToColour("gpg_" + e.identifier + "_" + f.array_config.entity_ref)} />
                                        }

                                        if (f.type === "json" && !f.json_config.reuse) {
                                            return f.json_config.fields.map(jf => {
                                                if (jf.entity_ref !== undefined && jf.entity_ref !== "") {                                                    
                                                    return <LineTo
                                                        key={"gpg_" + e.identifier + "_" + jf.entity_ref + "_" + i}
                                                        within='entity-container'
                                                        delay={true}
                                                        from={e.identifier}
                                                        to={jf.entity_ref}
                                                        borderWidth={4}
                                                        className='entity-relationship'
                                                        borderColor={stringToColour("gpg_" + e.identifier + "_" + jf.entity_ref)} />
                                                }
                                                return null
                                            });                                                                                        
                                        }
                                        return null
                                    })
                                })
                            }
                            {
                                this.state.project.entities.map((entity) => {
                                    return <EntityCard key={entity.identifier} entity={entity} project={project} />
                                })
                            }


                        </div>
                    </div>
                </div>
                <SlidingPanel
                    type={'bottom'}
                    isOpen={this.state.entityEditorOpen}
                    size={75}
                    backdropClicked={() => this.closeEntityEditor()} >
                    <EntityEditor entity={this.state.selectedEntity} project={this} />
                </SlidingPanel>

                {this.state.modalsOrder.map((id) => {
                    var modalProps = this.state.modals.get(id)
                    return <Modal key={modalProps.id} ref={modalProps.reactRef} {...modalProps} />
                })}

            </div>
        )
    }
}

export default Project

var newEntity = `
{
    "identifier": "new_entity",
    "render": {
        "name": "",
        "description": "",
        "x": 101,
        "y": 102
    },
    "operations": [
        "select",
        "upsert"
    ],
    "validations": [],
    "fields": [
        {
            "identifier": "uuid",
            "render": {
                "name": "UUID",
                "description": "Unique Identifier"
            },
            "type": "uuid",
            "deprecated": false,
            "required": true,
            "stored": true,
            "storage_config": {
                "primary_key": true
            },
            "autogenerated": {
                "type": "uuid"
            },
            "hidden": {},
            "validations": [
                {
                "operation": "upsert",
                "type": "rule",
                "rule": "unique"
                }
            ]
        },
        {
            "identifier": "status",
            "render": {
                "name": "Status",
                "description": "Status of the record"
            },
            "type": "options_single",
            "json_config": {
                "type": "",
                "fields": []
            },
            "values": [
                {
                "identifier": "active",
                "display": "Active"
                },
                {
                "identifier": "disabled",
                "display": "Disabled"
                }
            ],
            "deprecated": false,
            "required": true,
            "stored": true,
            "storage_config": {
                "index": true
            },
            "autogenerated": {},
            "hidden": {},
            "validations": []
            },
            {
            "identifier": "created_at",
            "render": {
                "name": "Created",
                "description": "When the record was created"
            },
            "type": "datetime",
            "deprecated": false,
            "required": true,
            "stored": true,
            "storage_config": {
                "index": true
            },
            "autogenerated": {
                "type": "insert_current_timestamp",
                "func_name": ""
            },
            "hidden": {},
            "validations": []
        },
        {
            "identifier": "updated_at",
            "render": {
                "name": "Updated",
                "description": "When the record was updated"
            },
            "type": "datetime",
            "entity_ref": "",
            "json_config": {
                "type": "",
                "fields": []
            },
            "values": [
                {
                "identifier": "",
                "display": ""
                }
            ],
            "deprecated": false,
            "required": true,
            "stored": true,
            "storage_config": {
                "index": true
            },
            "autogenerated": {
                "type": "update_current_timestamp",
                "func_name": ""
            },
            "hidden": {},
            "validations": []
        },
        {
            "identifier": "created_by_uuid",
            "render":
            {
                "name": "Created by user",
                "description": "The user that created the record"
            },
            "type": "uuid",
            "entity_ref": "",
            "json_config":
            {
                "type": "single",
                "fields":
                []
            },
            "array_config":
            {
                "type": "string"
            },
            "values":
            [
                {
                    "identifier": "",
                    "display": ""
                }
            ],
            "deprecated": false,
            "required": true,
            "stored": true,
            "storage_config":
            {},
            "autogenerated":
            {},
            "hidden":
            {
                "api":
                [],
                "admin":
                []
            },
            "validations":
            []
        },
        {
            "identifier": "updated_by_uuid",
            "render":
            {
                "name": "Updated by user",
                "description": "When the user last updated the record"
            },
            "type": "uuid",
            "entity_ref": "",
            "json_config":
            {
                "type": "single",
                "fields":
                []
            },
            "array_config":
            {
                "type": "string"
            },
            "values":
            [
                {
                    "identifier": "",
                    "display": ""
                }
            ],
            "deprecated": false,
            "required": true,
            "stored": true,
            "storage_config":
            {},
            "autogenerated":
            {},
            "hidden":
            {
                "api":
                [],
                "admin":
                []
            },
            "validations":
            []
        }
    ]
}   
`