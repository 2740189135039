/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

class CustomQueriesEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {    
            project: props.project,
            entity: props.entity,        
        }
    }

    render() {
        return (<div className="customQueries">
             <div className="sectionHeader">
                <div className='title'>
                    <h1>Custom <span>{this.state.entity.render.name}</span> Queries</h1>                     
                </div>
                <a className="btn add" onClick={() => {
                        if (this.state.entity.custom_queries == undefined) {
                            this.state.entity.custom_queries = []
                        }
                        this.state.entity.custom_queries.push(newCustomQuery())
                        this.setState(this.state)
                        this.state.project.setState(this.state.project.state)
                    }} >Add Query</a>
            </div>
            
            <ul>
                {this.state.entity.custom_queries?.map((query, index) => {
                    return <div key={index}>
                        <li>
                            <span onClick={() => {
                                    this.state.project.openModal("customQuery", {...this.state, query:query}) 
                                }}>{query.name}</span>

                            <a className="btn remove" onClick={() => {
                                    this.state.entity.custom_queries = this.state.entity.custom_queries.filter((cq) => cq.name !== query.name)
                                    this.setState(this.state)
                                    this.state.project.setState(this.state.project.state)
                                }}>Remove</a> 

                            <a className='btn' style={{float:"right",marginRight:"10px"}} onClick={
                                () => {
                                    this.state.project.openModal("customQuery", {...this.state, query:query}) 
                                }}>Edit</a>
                            
                        </li>
                    </div>
                }) }
            </ul>
        </div>)
    }
}

function newCustomQuery() {
    return {
        "name": "new-" + Date.now(),
        "condition": {
          "operator": "AND",
          "comparisons": [],
          "conditions": [],
        },
        "joins": []
      }
}


export default CustomQueriesEditor