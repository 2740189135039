import React from 'react'
import Validation from './validation'

//var operations = ["select", "upsert", "delete"]
class FieldValidations extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            field: props.field,
            entity: props.entity,
            project: props.project,
        }
    }

    render() {
        var field = this 
        return (
            <div style={{ padding: "10px 20px" }}>
                <b style={{ fontSize: "20px" }}>Validations</b>
                <br /><br />
                {
                    this.state.field?.validations?.map((el, i) => 
                        <Validation key={i} validation={el} field={field} />
                    )
                }
                <div className='editorButton'
                    onClick={() => {
                        this.setState((cs)=>{
                            cs.field.validations[cs.field.validations.length] = {}
                            return cs
                        })
                    }}
                > + Add Validation</div>
                <br /><br />
            </div>
        )
    }
}

export default FieldValidations