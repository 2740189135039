import React from 'react'
import FieldRow from './fieldRow'
import { List, arrayMove } from 'react-movable'


class FieldsEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            json: props.json,
            project: props.project,
            entity: props.entity === undefined ? {} : props.entity
        }
    }

    render() {
        var project = this.state.project
        var entity = this.state.entity
        var isJson = this.state.json

        return (
            <div style={{ width: "98%", padding: "5px 1%", height: "88%", backgroundColor: "#FFF", overflow: "scroll" }}>
                <div style={{backgroundColor: "#EEE"}}>
                    <List
                        ref={"entityFields"}
                        values={this.state.entity?.fields}
                        onChange={
                            ({ oldIndex, newIndex }) => {

                                var p = this.state.project.state.project
                                var entity = this.state.entity
                                for (var i in p.entities) {
                                    if (p.entities[i].identifier === entity.identifier) {
                                        p.entities[i] = entity
                                        break;
                                    }
                                }

                                this.setState((cs) => {
                                    cs.entity.fields = arrayMove(cs.entity?.fields, oldIndex, newIndex)
                                    cs.project.state.project = p
                                    return cs
                                })
                                this.state.project.setState(this.state.project.state)
                            }
                        }
                        renderList={({ children, props, isDragged }) => (
                            <table className="fieldsTable">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Type</th>
                                        <th>Type Settings</th>
                                        {!this.state.json ? <th>Primary</th> : ''}
                                        <th>Required</th>
                                        {!this.state.json ? <th>Stored</th> : ''}
                                        {!this.state.json ? <th>Unique</th> : ''}
                                        {!this.state.json ? <th>Index</th> : ''}
                                        {!this.state.json ? <th>Search</th> : ''}
                                        <th>Deprecated</th>
                                        <th>Hidden</th>
                                        <th>Validations</th>
                                        <th>Render</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody {...props}>{children}</tbody>
                            </table>
                        )}
                        renderItem={({ value, props, isDragged, isSelected }) => {
                            const row = (
                                <FieldRow props={props} key={value.identifier} field={value} entity={entity} project={project} json={isJson} />
                            );
                            return isDragged ? (
                                <table className="fieldsTable" >
                                    <tbody>{row}</tbody>
                                </table>
                            ) : (
                                row
                            );
                        }}
                    />


                    <div className='editorButton'
                        style={{margin:"7px 5px", padding:"5px"}}
                        onClick={() => {
                            this.setState((cs) => {
                                var nf = JSON.parse(newField)
                                if (cs.entity?.fields !== undefined) {
                                    cs.entity.fields[this.state.entity.fields.length] = nf
                                } else {
                                    cs.entity.fields = [nf]
                                }
                                return cs
                            })
                            this.state.project.setState(this.state.project.state)
                        }}
                    > + Add Field</div>
                </div>
            </div>
        )
    }
}

export default FieldsEditor

var newField = `
{
    "identifier": "",
    "render": {
        "name": "",
        "description": ""
    },
    "type": "",
    "entity_ref":"",
    "json_config": {
        "type": "single",
        "fields": []
    },
    "array_config":{
        "type":"string"
    },    
    "values":[
        {
            "identifier":"",
            "display":""
        }
    ],
    "deprecated": false,
    "required": false,
    "stored": true,
    "storage_config":{},
    "autogenerated": {},
    "hidden": {},
    "validations": []
}
`