import React from 'react'
import SlidingPanel from 'react-sliding-side-panel'
import FieldTypeConfig from './fieldTypeConfig'
import FieldHidden from './fieldHidden'
import FieldValidations from './fieldValidations'
import RenderEditor from './renderEditor'
import CustomQueriesEditor from './customQueriesEditor'

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            contentType:props.contentType,
            contentProps:props.contentProps,
            open:false,
            onOpen:props.onOpen,
            onClose:props.onClose,
            count:props.count,            
        }
    }

    componentDidMount(){
        setTimeout(()=>{
            if (this.props.open){
                this.open()
            }
        },100)
    }

    open(){
        this.setState((cs)=>{
            cs.open = true
            if (cs.onOpen !== undefined){
                cs.onOpen(this)
            }
            return cs
        })
    }

    close(){
        this.setState((cs)=>{
            cs.open = false
            if (cs.onClose !== undefined){
                setTimeout(()=>{
                    cs.onClose(this)
                },100)                
            }
            return cs
        })
    }
    render() {
        return <SlidingPanel
            id ={this.state.id}
            panelClassName='modalPanel'
            panelContainerClassName='modalPanel'
            type={'top'}
            isOpen={this.state.open}
            size={this.state.count !== undefined ? (75 - this.state.count) : 75}
            backdropClicked={() => this.close()} >
            <div style={{ display: "inline-block", backgroundColor: "#FFF", width: "100%", height: "99%" }}>
                {this.state.contentType === "fieldType" ?
                    <FieldTypeConfig {...this.state.contentProps} />
                    : ''}
                {this.state.contentType === "fieldHidden" ?
                    <FieldHidden {...this.state.contentProps} />
                    : ''}
                {this.state.contentType === "fieldValidations" ?
                    <FieldValidations {...this.state.contentProps} />
                    : ''}
                {this.state.contentType === "fieldRender" ?
                    <RenderEditor {...this.state.contentProps} />
                    : ''}
                {this.state.contentType === "customQueries" ?
                    <CustomQueriesEditor {...this.state.contentProps} />
                    : ''}
            </div>
        </SlidingPanel>
    }
}

export default Modal