/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

class Option extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            field: props.field,
            option: props.option
        }
    }

    render() {
        return (
            <div style={{display:"block", padding:"5px"}}>
                Identifier:
                <input
                    type="text"
                    id="identifier"
                    name="identifier"
                    value={this.state.option?.identifier}
                    onChange={(e)=>{
                        this.setState((cs)=>{
                            cs.option.identifier = e.target.value
                            return cs
                        })                                                
                    }}
                    required />
                &nbsp;
                Display:
                <input
                    type="text"
                    id="display"
                    name="display"
                    value={this.state.option?.display}
                    onChange={(e)=>{
                        this.setState((cs)=>{
                            cs.option.display = e.target.value
                            return cs
                        })                                             
                    }}
                    required />
                &nbsp;   
                <a href='#' onClick={
                    () => {
                        this.setState((cs)=>{
                            var filtered = cs.field.state.field.values.filter((el) => el.identifier !== cs.option.identifier ); 
                            cs.field.state.field.values = filtered                        
                            cs.field.setState(cs.field.state)                                                            
                            return cs
                        })
                        
                    }
                }>Remove</a>
            </div>
        )
    }
}

export default Option