import React from 'react'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FieldsEditor from './fieldsEditor';


class EntityEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            project: props.project,
            entity: props.entity
        }
    }

    render() {       
        if (!this.state.entity) {
            return (<div></div>)
        }
        return (
            <div style={{ display: "inline-block", backgroundColor: "#FFF", width: "100%", height: "99%", overflow: "hidden" }}>
                <div style={{ padding: "5px", margin:"0", display: "block", overflow: "hidden" }}>
                    <div style={{ padding: "10px", float: "left", fontSize: "20px", fontWeight: "bold" }}>
                        {this.state.entity.render.name}<span style={{ fontSize: "16px", fontWeight: "200" }}> | {this.state.entity.render.description}</span>
                    </div>
                    <div style={{padding: "10px"}}>
                        <div className='editorButton' style={{marginLeft: "10px", float: "right", backgroundColor: "#CC0000", color: "#FFF", fontWeight: "bold" }}
                            onClick={() => {
                                this.state.project.closePanel()
                                confirmAlert({
                                    title: 'Confirm deletion',
                                    message: 'Are you sure to delete this entity?',
                                    buttons: [
                                        {
                                            label: 'Yes',
                                            onClick: () => {
                                                this.setState((cs)=>{
                                                    var filtered = cs.project.state.project.entities.filter((el) => el.identifier !== cs.entity.identifier);
                                                    cs.project.state.project.entities = filtered
                                                    cs.project.setState(cs.project.state)
                                                    return cs
                                                })
                                                
                                            }
                                        },
                                        {
                                            label: 'No',
                                            onClick: () => { }
                                        }
                                    ]
                                });
                            }}
                        >
                            DELETE
                        </div>
                        <div className='editorButton' style={{ marginLeft: "10px", float: "right" }}
                            onClick={
                                () => {
                                    this.state.project.openModal("fieldRender", {...this.state, type:"entity", render:this.state.entity.render})
                                }
                            }
                        >
                            Edit Config
                        </div>
                        <div className='editorButton' style={{ marginRight: "10px", float: "right" }}
                            onClick={
                                () => {
                                    this.state.project.openModal("customQueries", {...this.state})
                                }
                            }
                        >
                            Custom Queries
                        </div>
                    </div>
                    <div style={{ clear: "both", borderBottom: "1px solid #EEE", width: "100%", paddingTop: "5px" }}></div>
                </div>

                <FieldsEditor entity={this.state.entity} project={this.state.project} json={false} />

            </div>
        )
    }
}

export default EntityEditor