import React from 'react'
import FieldCard from './fieldCard'
import Draggable from 'react-draggable';


var cardStyles = {
    minWidth: "190px",
    minHeight: "300px",
    backgroundColor: "#DDD",
    borderRadius: "10px",
    border: "2px solid #EEE",
    overflow:"hidden",         
    position:"absolute"
}

var entityNameStyles = {
    width: "92%",
    padding: "4% 4%",
    backgroundColor: "#b0defe",
    borderBottom: "2px solid #AAA",
    textAlign: "left",
    fontSize: "14px",
    cursor:"pointer",
    fontWeight:"bold"
}

class EntityCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            project: props.project,
            entity: props.entity,
            x: props.entity.render?.x ? props.entity.render.x : 100,
            y: props.entity.render?.y ? props.entity.render.y : 100
        }
    }

    onDrag(e) {    
        this.setState((cs)=>{
            cs.entity.render.x = e.layerX-e.offsetX
            cs.entity.render.y = e.layerY-e.offsetY        
            return cs
        })                     
        this.state.project.setState(this.state.project.state)
    }

    render() {
        var entity = this.state.entity
        return (
            <Draggable                    
            bounds={"parent"}
            offsetParent={document.getElementById('entity-container')}            
            defaultPosition={{x: this.state.x, y:this.state.y}}
            onDrag={(e) => {
                this.onDrag(e)                
            }} 
            onStop={(e) => this.onDrag(e)} >
                <div style={cardStyles} className={"entityCard "+this.state.entity.identifier}
                onDoubleClick={() => {
                    this.state.project.openEntityEditor(this.state.entity)
                }}
                >
                    <div style={entityNameStyles} 
                        onMouseOver={(e)=>{e.target.style.backgroundColor = "#009AFF"}}
                        onMouseLeave={(e)=>{e.target.style.backgroundColor = "#b0defe"}}
                        >{this.state.entity.identifier}</div>
                    {
                        this.state.entity.fields?.map(function (field) {
                            return <FieldCard key={field.identifier} field={field} entity={entity} />
                        })
                    }        
                </div>
            </Draggable>
        )
    }
}

export default EntityCard