import React from 'react'
import FieldsEditor from './fieldsEditor'
import Option from './option'

class FieldTypeConfig extends React.Component {
    constructor(props) {
        super(props)
        if (props.field.json_config === undefined) {
            props.field.json_config = {
                type: "single",
                fields: [],
                identifier:"",
                reuse:false,
            }
        }
        if (props.field?.array_config === undefined) {
            props.field.array_config = {
                type: "string",
                entity_ref: ""
            }
        }
        this.state = {
            field: props.field,
            entity: props.entity,
            project: props.project,
        }
    }

    render() {
        return (
            <div style={{ padding: "10px 20px" }}>
                {this.state.field?.type === "uuid" ?
                    this.renderUUIDConfig()
                    : null}
                {this.state.field?.type === "json" ?
                    this.renderJSONConfig()
                    : null}
                {this.state.field?.type === "options_single" || this.state.field?.type === "options_many" ?
                    this.renderOptionsConfig()
                    : null}
                {this.state.field?.type === "array" ?
                    this.renderArrayConfig()
                    : null}

                {this.renderProcessingOptions()}

                <br /><br />
                <br /><br />
            </div>
        )
    }

    renderUUIDConfig() {
        return <div>
            <b style={{ fontSize: "20px" }}>{this.state.field.identifier} | Entity Reference</b>
            <br /><br />
            Entity: &nbsp;
            <select id="entity_ref"
                name="entity_ref" value={this.state.field?.entity_ref}
                onChange={(e) => {
                    this.setState((currState) => {
                        currState.field.entity_ref = e.target.value
                        return currState
                    })
                }}
                onBlur={(e) => {
                    this.state.project.setState(this.state.project.state)
                }}
            >
                <option value=""></option>
                {
                    this.state.project.state.project.entities.map(((e) => {
                        return <option key={e.identifier} value={e.identifier}>{e.identifier}</option>
                    }))
                }
            </select>
            <br /><br />
        </div>
    }

    renderJSONConfig() {
        return <div>
            <b style={{ fontSize: "20px" }}>{this.state.field.identifier} | JSON Configuration</b>
            <br /><br />                        
            <input type="checkbox" name="many"
                style={{ height: 'auto' }}
                checked={this.state.field?.json_config?.type === "many" ? "checked" : ""}
                onChange={(e) => {
                    this.setState((cs) => {
                        cs.field.json_config.type = e.target.checked ? 'many' : 'single'
                        return cs
                    })
                    this.state.project.setState(this.state.project.state)
                }} /> &nbsp; Multiple
            <br/>
            <input type="checkbox" name="reuse"
                style={{ height: 'auto' }}
                checked={this.state.field?.json_config?.reuse ? "checked" : ""}
                onChange={(e) => {
                    this.setState((cs) => {
                        cs.field.json_config.reuse = e.target.checked
                        if (cs.field.json_config.reuse) {
                            cs.field.json_config.fields = []  
                        }
                        return cs
                    })
                    this.state.project.setState(this.state.project.state)
                }} /> &nbsp; Reuse Existing
            <br/><br/>
            {this.state.field?.json_config?.reuse ? 
                <div>
                    Identifier: &nbsp;
                    <select id="reuse_nested"
                        name="reuse_nested" 
                        value={this.state.field?.json_config?.identifier}
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.field.json_config.identifier = e.target.value
                                return currState
                            })
                        }}
                        onBlur={(e) => {
                            this.state.project.setState(this.state.project.state)
                        }}
                    >
                        <option value=""></option>
                        {getNestedFromEntities(this.state.project.state.project.entities).map((ne)=>{
                            return <option key={ne} value={ne}>{ne}</option>
                        })}
                    </select>
                </div>
                :
                <div>
                    <span>Identifier:</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((cs)=>{                                
                                cs.field.json_config.identifier = e.target.value
                                return cs
                            })                            
                            this.state.project.setState(this.state.project.state)
                        }}
                        type="text"
                        id="identifier"
                        name="func_name"
                        value={this.state.field?.json_config?.identifier}
                        required />
                    <br /><br />
                    <FieldsEditor entity={this.state.field.json_config} project={this.state.project} json={true} />
                </div>                
            }
            
            
            <br /><br />
        </div>
    }

    

    renderOptionsConfig() {
        return <div>
            <b style={{ fontSize: "20px" }}>{this.state.field.identifier} | Options</b>
            <br /><br />
            {
                this.state.field?.values.map((value, i) => {
                    return <Option key={value} option={value} field={this} />
                })
            }
            <br />
            <div className='editorButton'
                onClick={() => {
                    this.setState((cs) => {
                        cs.field.values[cs.field.values.length] = {}
                        return cs
                    })
                }}
            > + Add Option</div>
            <br /><br />
        </div>
    }

    renderArrayConfig() {
        return <div>
            <b style={{ fontSize: "20px" }}>{this.state.field.identifier} | Array Type</b>
            <br /><br />
            Type: &nbsp;
            <select id="array_type"
                name="array_type" value={this.state.field?.array_config?.type}
                onChange={(e) => {
                    this.setState((currState) => {
                        currState.field.array_config.type = e.target.value
                        return currState
                    })
                }}
                onBlur={(e) => {
                    this.state.project.setState(this.state.project.state)
                }}
            >
                <option value=""></option>
                <option value="uuid">uuid</option>
                <option value="int">int</option>
                <option value="float">float</option>
                <option value="boolean">boolean</option>
                <option value="string">string</option>
                <option value="date">date</option>
                <option value="datetime">datetime</option>
            </select>
            <br /><br />

            {this.state.field?.array_config?.type === "uuid" ?
                <div>
                    Entity: &nbsp;
                    <select id="entity_ref"
                        name="entity_ref" value={this.state.field?.array_config?.entity_ref}
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.field.array_config.entity_ref = e.target.value
                                return currState
                            })
                        }}
                        onBlur={(e) => {
                            this.state.project.setState(this.state.project.state)
                        }}
                    >
                        <option value=""></option>
                        {
                            this.state.project.state.project.entities.map(((e) => {
                                return <option key={e.identifier} value={e.identifier}>{e.identifier}</option>
                            }))
                        }
                    </select>
                    <br /><br />
                </div>
                : <div><br /><br /></div>}
        </div>
    }

    renderProcessingOptions() {
        return <div><b style={{ fontSize: "20px" }}>{this.state.field.identifier} | Processing</b>
            <br /><br />
            <span>Type</span>
            &nbsp;
            <select
                onChange={(e) => {
                    this.setState((cs)=>{
                        cs.field.autogenerated.type = e.target.value
                        cs.field.autogenerated.func_name = ""
                        return cs
                    })                                       
                    this.state.project.setState(this.state.project.state)
                }}
                value={this.state.field?.autogenerated?.type}
            >
                <option value="">none</option>
                {this.state.field?.type === "uuid" ?
                    <option value="uuid">uuid</option> : undefined}
                {this.state.field?.type === "datetime" || this.state.field?.type === "date" ?
                    <option value="insert_current_timestamp"> current timestamp (insert only)</option>
                    : undefined}
                {this.state.field?.type === "datetime" || this.state.field?.type === "date" ?
                    <option value="update_current_timestamp"> current timestamp (on update)</option>
                    : undefined}
                {this.state.field?.type === "string" ?
                    <option value="encrypt">encrypt (bcrypt)</option>
                    : undefined}
                <option value="custom">custom</option>

            </select>

            {this.state.field?.autogenerated?.type === "custom" ?
                <div>
                    <br />
                    <span>Function Name</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((cs)=>{                                
                                cs.field.autogenerated.func_name = e.target.value
                                return cs
                            })                            
                            this.state.project.setState(this.state.project.state)
                        }}
                        type="text"
                        id="identifier"
                        name="func_name"
                        value={this.state.field.autogenerated.func_name}
                        required />
                </div>
                : ''}
        </div>
    }
}

function getNestedFromEntities(entities){
    var nested = new Map()
    entities.forEach(e => {
        var n = getNestedFromEntity(e)
        n.forEach((e)=>{
            nested.set(e, null)
        })        
    });
    return Array.from( nested.keys() )
}

function getNestedFromEntity(entity){
    var nested = new Map()
    entity.fields.forEach(f => {
        if (f.type === "json"){
            if (!f.json_config.reuse && f.json_config.fields.length > 0){                        
                if (f.json_config?.identifier && f.json_config?.identifier !== ""){
                    nested.set(f.json_config.identifier, null)
                } else {                            
                    nested.set(f.identifier, null)
                }
            }
            
            f.json_config.fields.forEach((jf)=>{
                if (jf.type === "json"){
                    var nn = getNestedFromEntity({
                        identifier: f.json_config?.identifier,
                        fields:  f.json_config.fields
                    })
                    nn.forEach((e)=>{
                        nested.set(e, null)
                    })                     
                }
            })
            
        }
    });
    return Array.from( nested.keys() )
}



export default FieldTypeConfig



