import React from 'react'

const AuthType = {
    Basic: 'basic',
    JWT: 'jwt',
    Keycloak: 'keycloak',
}

class AuthConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            project: props.project,            
        }
    }    

    render() {        
        return (
            <div>
                <b style={{ fontSize: "20px" }}>Auth</b>
                <br />
                {this.renderBasicAuth()}
                <br />
                {this.renderAPIAuth()}


            </div>
        )
    }

    renderBasicAuth() {
        var basicAuth = this.getAuthByType(AuthType.Basic)
        return <div style={{ display: "block", padding: "10px", border: '1px solid #CCC', marginTop: '10px', marginBottom: '10px' }}>
            <b style={{ fontSize: "16px" }}>Basic Auth (used to authenticated http tools)</b>
            <br />
            <span>
                <input
                    type="checkbox"
                    name="basic_enabled"
                    checked={basicAuth.enabled === true ? "checked" : ""}
                    onChange={(e) => {
                        if (basicAuth.enabled === true) {
                            this.updateAuthByType(AuthType.Basic, false)                            
                        } else {
                            this.updateAuthByType(AuthType.Basic, true)                            
                        }
                    }}
                    aria-label='Enabled'
                />
                <span style={{ float: 'left', paddingTop: '7px' }} className='inputLabel'>Enabled</span>
            </span>
            <br />
            {basicAuth.enabled === true ?
                <div>
                    <span className='inputLabel'>Username</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            if (basicAuth.config.basic === undefined) {
                                basicAuth.config = {}
                                basicAuth.config.basic = {}
                            }
                            basicAuth.config.basic.username = e.target.value
                            this.updateAuthByType(AuthType.Basic, true, basicAuth.config)                            
                        }}
                        type="text"
                        id="auth_username"
                        name="auth_username"
                        value={basicAuth?.config?.basic?.username}
                        required />
                    <br />
                    <span className='inputLabel'>Password</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            if (basicAuth.config.basic === undefined) {
                                basicAuth.config = {}
                                basicAuth.config.basic = {}
                            }
                            basicAuth.config.basic.password = e.target.value
                            this.updateAuthByType(AuthType.Basic, true, basicAuth.config)                            
                        }}
                        type="text"
                        id="auth_pass"
                        name="auth_pass"
                        value={basicAuth?.config?.basic?.password}
                        required />
                    <br />
                </div>
                : null}
        </div>
    }

    renderAPIAuth() {
        return <div style={{ display: "block", padding: "10px", border: '1px solid #CCC', marginTop: '10px', marginBottom: '10px' }}>
            <b style={{ fontSize: "16px" }}>API Auth</b>
            <br />
            <span>
                <input
                    type="checkbox"
                    name="api_enabled"
                    checked={this.isAPIAuthEnabled() ? "checked" : ""}
                    onChange={(e) => {
                        if (this.isAPIAuthEnabled()) {
                            this.updateAuthByType(AuthType.JWT, false)
                            this.updateAuthByType(AuthType.Keycloak, false)                            
                        } else {                            
                            this.updateAuthByType(AuthType.Keycloak, false)                            
                            this.updateAuthByType(AuthType.JWT, true)
                        }
                    }}
                    aria-label='Enabled'
                />
                <span style={{ float: 'left', paddingTop: '7px' }} className='inputLabel'>Enabled</span>
            </span>
            <br />
            {this.isAPIAuthEnabled() ?
                <div>
                    <span>
                        <select
                            className='select'
                            name="authapitype"
                            value={this.getAPIAuthType()}
                            onChange={(e) => {
                                var newType = e.target.value
                                if (newType === AuthType.JWT) {
                                    this.updateAuthByType(AuthType.Keycloak, false)
                                    this.updateAuthByType(newType, true)                                    
                                } else if (newType === AuthType.Keycloak) {
                                    this.updateAuthByType(AuthType.JWT, false)
                                    this.updateAuthByType(newType, true)                                    
                                } else {
                                    this.updateAuthByType(AuthType.Keycloak, false)
                                    this.updateAuthByType(AuthType.JWT, false)                                    
                                }

                            }}
                        >
                            <option value={AuthType.JWT}>JWT (in memory)</option>
                            <option value={AuthType.Keycloak}>Keycloak</option>
                        </select>
                        <span style={{ float: 'left', paddingTop: '7px' }} className='inputLabel'>Type</span>
                    </span>
                    <br />
                    <br />
                    <b style={{ fontSize: "14px" }}>Configuration</b>

                    {this.getAPIAuthType() === AuthType.JWT ? this.renderJWTConfig() : null}

                    {this.getAPIAuthType() === AuthType.Keycloak ? this.renderKeycloakConfig() : null}
                </div>
                : null}
        </div>
    }

    renderJWTConfig() {
        var jwtAuth = this.getAuthByType(AuthType.JWT)
        return <span>
            <div style={{ display: "block", padding: "5px" }}>
                <span className='inputLabel'>JWT Key</span>
                &nbsp;
                <input
                    onChange={(e) => {

                        if (jwtAuth.config.jwt === undefined) {
                            jwtAuth.config = {}
                            jwtAuth.config.jwt = {}
                        }
                        jwtAuth.config.jwt.key = e.target.value
                        this.updateAuthByType(AuthType.JWT, true, jwtAuth.config)                        
                    }}
                    type="text"
                    id="jwt_key"
                    name="jwt_key"
                    value={jwtAuth?.config?.jwt?.key}
                    required />
                <br />
            </div>
        </span>
    }

    renderKeycloakConfig() {
        var keycloakAuth = this.getAuthByType(AuthType.Keycloak)        
        return <span>
            <div style={{ display: "block", padding: "5px" }}>
                <span className='inputLabel'>Hotname</span>
                &nbsp;
                <input
                    onChange={(e) => {
                        if (keycloakAuth.config.keycloak === undefined) {
                            keycloakAuth.config = {}
                            keycloakAuth.config.keycloak = {}
                        }
                        keycloakAuth.config.keycloak.hostname = e.target.value
                        this.updateAuthByType(AuthType.Keycloak, true, keycloakAuth.config)                        
                    }}
                    type="text"
                    id="keycloak_hostname"
                    name="keycloak_hostname"
                    value={keycloakAuth?.config?.keycloak?.hostname}
                    required />
                <br />
            </div>
            <div style={{ display: "block", padding: "5px" }}>
                <span className='inputLabel'>Realm</span>
                &nbsp;
                <input
                    onChange={(e) => {
                        if (keycloakAuth.config.keycloak === undefined) {
                            keycloakAuth.config = {}
                            keycloakAuth.config.keycloak = {}
                        }
                        keycloakAuth.config.keycloak.realm = e.target.value
                        this.updateAuthByType(AuthType.Keycloak, true, keycloakAuth.config)                        
                    }}
                    type="text"
                    id="keycloak_realm"
                    name="keycloak_realm"
                    value={keycloakAuth?.config?.keycloak?.realm}
                    required />
                <br />
            </div>
            <div style={{ display: "block", padding: "5px" }}>
                <span className='inputLabel'>ClientID</span>
                &nbsp;
                <input
                    onChange={(e) => {
                        if (keycloakAuth.config.keycloak === undefined) {
                            keycloakAuth.config = {}
                            keycloakAuth.config.keycloak = {}
                        }
                        keycloakAuth.config.keycloak.client_id = e.target.value
                        this.updateAuthByType(AuthType.Keycloak, true, keycloakAuth.config)                        
                    }}
                    type="text"
                    id="keycloak_client_id"
                    name="keycloak_client_id"
                    value={keycloakAuth?.config?.keycloak?.client_id}
                    required />
                <br />
            </div>
            <div style={{ display: "block", padding: "5px" }}>
                <span className='inputLabel'>ClientSecret</span>
                &nbsp;
                <input
                    onChange={(e) => {
                        if (keycloakAuth.config.keycloak === undefined) {
                            keycloakAuth.config = {}
                            keycloakAuth.config.keycloak = {}
                        }
                        keycloakAuth.config.keycloak.client_secret = e.target.value
                        this.updateAuthByType(AuthType.Keycloak, true, keycloakAuth.config)                        
                    }}
                    type="text"
                    id="keycloak_client_secret"
                    name="keycloak_client_secret"
                    value={keycloakAuth?.config?.keycloak?.client_secret}
                    required />
                <br />
            </div>
        </span>
    }

    getAuthByType(type) {
        if (!this.state.project || !this.state.project.auth) {
            return { enabled: false, type }
        }
        var filtered = this.state.project.auth.filter(a => {
            if (a.type === type) {
                return true
            }
            return false
        });
        if (filtered.length > 0) {
            return filtered[0]
        }
        return { enabled: false, type }
    }

    

    updateAuthByType(type, enabled, config) {        
        if (!this.state.project.auth) {
            this.setState((currState) =>{
                currState.auth = [{ enabled, type, config }]
                return currState                
            })
            return             
        }
        var found = false
        var newAuths = []
        this.state.project.auth.forEach(a => {
            if (a.type === type) {
                a.enabled = enabled
                if (undefined !== config) {
                    a.config = config
                }
                found = true
            }
            newAuths.push(a)
        });

        if (!found) {
            this.setState((currState) => {
                currState.project.auth = [...currState.project.auth,{ enabled, type, config }]
                return currState                
            })
            
        } else {
            this.setState((currState) =>{
                currState.project.auth = newAuths
                return currState                  
            })                     
        }
    }

    isAPIAuthEnabled() {
        var jwtAuth = this.getAuthByType(AuthType.JWT)
        var keycloakAuth = this.getAuthByType(AuthType.Keycloak)
        if (jwtAuth.enabled === true || keycloakAuth.enabled === true) {
            return true
        }
        return false
    }

    getAPIAuthType() {
        var jwtAuth = this.getAuthByType(AuthType.JWT)
        var keycloakAuth = this.getAuthByType(AuthType.Keycloak)
        if (jwtAuth.enabled === true) {
            return AuthType.JWT
        }

        if (keycloakAuth.enabled === true) {
            return AuthType.Keycloak
        }
        return ""
    }
}



export default AuthConfig