/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

class FieldRow extends React.Component {
    constructor(props) {
        super(props)
        if (props.field.storage_config === undefined) {
            props.field.storage_config = {
                primary_key:false,
                index:false,
                search:false,
                unique:false
            }
        }

        if (props.field.hidden === undefined) {
            props.field.hidden = { "api": [], "admin": [] }
        }
        if (props.field.hidden.api === undefined) {
            props.field.hidden.api = []
        }
        if (props.field.hidden.admin === undefined) {
            props.field.hidden.admin = []
        }

        if (props.field.validations === undefined) {
            props.field.validations = []
        }

        if (props.field.render === undefined) {
            props.field.render = {}
        }
        this.state = {
            identifier: props.field.identifier,
            json: props.json,
            field: props.field,
            entity: props.entity,
            project: props.project,
        }
    }

    render() {
        var numChecked = this.state.entity.fields.filter((el) => el.storage_config?.primary_key).length
        return (
            <tr {...this.props.props}>
                <td><input
                    type="text"
                    id="identifier"
                    name="identifier"
                    value={this.state.field?.identifier}
                    onChange={(e) => {
                        this.setState((currState)=>{
                            currState.field.identifier = e.target.value
                            return currState
                        })                                                
                    }}
                    onBlur={(e) => {
                        this.state.project.setState(this.state.project.state)
                    }}
                    required /></td>
                <td>
                    <select id="type"
                        name="type" value={this.state.field?.type}
                        onChange={(e) => {
                            this.setState((currState)=>{
                                currState.field.type = e.target.value
                                return currState
                            })                          
                        }}
                        onBlur={(e) => {
                            this.state.project.setState(this.state.project.state)
                        }}
                    >
                        <option value=""></option>
                        <option value="uuid">uuid</option>
                        <option value="int">int</option>
                        <option value="float">float</option>
                        <option value="boolean">boolean</option>
                        <option value="string">string</option>
                        <option value="large_string">large string</option>
                        <option value="array">array</option>                        
                        <option value="json">json</option>                        
                        <option value="options_single">options_single</option>
                        <option value="options_many">options_many</option>
                        <option value="date">date</option>
                        <option value="datetime">datetime</option>
                    </select>
                </td>
                <td><a href="#" onClick={() => {
                    
                        this.state.project.openModal("fieldType", this.state)
                    
                }}>Edit 
                {this.state.field.autogenerated?.type !== undefined && this.state.field.autogenerated?.type !== "" ? "(" + this.state.field.autogenerated.type + ")" : ""}
                {this.state.field?.entity_ref !== undefined && this.state.field?.entity_ref !== "" ? "(" + this.state.field.entity_ref + ")" : ""}
                {this.state.field?.type === "array" && this.state.field?.array_config?.type !== undefined && this.state.field?.array_config?.type !== "" ? "(" + this.state.field?.array_config?.type + ")" : ""}
                {this.state.field?.type === "json" && this.state.field?.json_config?.identifier !== undefined && this.state.field?.json_config?.identifier !== "" ? "(" + this.state.field?.json_config?.identifier + ")" : ""}
                </a>
                </td>

                {!this.state.json ?
                    <td><input type="checkbox" name="primary_key"
                        disabled={!this.state.field.storage_config?.primary_key && numChecked === 1 ?
                            "disabled"
                            :
                            ''}
                        checked={this.state.field.storage_config?.primary_key ? "checked" : ""}
                        onChange={(e) => {
                            this.setState((cs)=>{
                                cs.field.storage_config.index = false
                                cs.field.storage_config.primary_key = e.target.checked
                                return cs
                            })                            
                            this.state.project.setState(this.state.project.state)
                        }} /></td>
                    : ''}
                <td><input type="checkbox" name="required"
                    checked={this.state.field.required ? "checked" : ""}
                    onChange={(e) => {
                        this.setState((cs)=>{                            
                            cs.field.required = e.target.checked
                            return cs
                        })                          
                        this.state.project.setState(this.state.project.state)
                    }} /></td>
                {!this.state.json ?
                    <td><input type="checkbox" name="stored"
                        checked={this.state.field.stored ? "checked" : ""}
                        onChange={(e) => {
                            this.setState((cs)=>{                            
                                cs.field.stored = e.target.checked
                                return cs
                            })                            
                            this.state.project.setState(this.state.project.state)
                        }} /></td>
                    : ''}
                {!this.state.json ?
                    <td><input type="checkbox" name="unique"
                        checked={this.state.field.storage_config?.unique ? "checked" : ""}
                        disabled={this.state.field.storage_config?.primary_key ||
                            this.state.field.type === "json" ||
                            this.state.field.type === "options_many"
                             ? 'disabled': ''} 
                        onChange={(e) => {
                            this.setState((cs)=>{                                
                                cs.field.storage_config.unique = e.target.checked
                                return cs
                            })                            
                            this.state.project.setState(this.state.project.state)
                        }} /></td>
                    : ''}
                {!this.state.json ?
                    <td><input type="checkbox" name="index"
                        checked={this.state.field.storage_config?.primary_key || this.state.field.storage_config?.index ? "checked" : ""}
                        disabled={this.state.field.storage_config?.primary_key ||
                             this.state.field.type === "json" ||
                             this.state.field.type === "options_many"
                              ? 'disabled': ''} 
                        onChange={(e) => {
                            this.setState((cs)=>{                                
                                cs.field.storage_config.index = e.target.checked
                                return cs
                            })                                                        
                            this.state.project.setState(this.state.project.state)
                        }} /></td>
                    : ''}
                {!this.state.json ?
                    <td><input type="checkbox" name="search"
                        checked={this.state.field.storage_config?.search ? "checked" : ""}
                        disabled={
                             (this.state.field.type !== "string" &&
                             this.state.field.type !== "large_string" &&
                             this.state.field.type !== "uuid")
                              ? 'disabled': ''} 
                        onChange={(e) => {
                            this.setState((cs)=>{                                
                                cs.field.storage_config.search = e.target.checked
                                return cs
                            })
                            this.setState(this.state)
                            this.state.project.setState(this.state.project.state)
                        }} /></td>
                    : ''}
                <td><input type="checkbox" name="deprecated"
                    checked={this.state.field.deprecated ? "checked" : ""}
                    onChange={(e) => {
                        this.setState((cs)=>{                                
                            cs.field.deprecated = e.target.checked
                            return cs
                        })                        
                        this.state.project.setState(this.state.project.state)
                    }} /></td>
                <td><a href='#' onClick={() => {                   
                   
                    this.state.project.openModal("fieldHidden", this.state)
                   
                }}>Edit</a></td>
                <td><a href='#' onClick={() => {                    
                    
                    this.state.project.openModal("fieldValidations", this.state)
                    
                }}>Edit</a></td>
                <td><a href='#' onClick={() => {                   
                    
                    this.state.project.openModal("fieldRender", { ...this.state, type: "field", render: this.state.field.render })
                    
                }}>Edit{this.state.field?.render.name ? '' : '(-r)'}</a></td>
                <td><a href='#' style={{color:"#CC0000"}} onClick={() => {
                    var filtered = this.state.entity.fields.filter((el) => el.identifier !== this.state.field.identifier);
                    this.setState((cs)=>{                                
                        cs.entity.fields = filtered
                        return cs
                    })                  
                    this.state.project.setState(this.state.project.state)
                }}>Delete</a></td>
            </tr>
        )
    }
}

export default FieldRow