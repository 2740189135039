import React from 'react'



var primaryKey = "●"
var required = "◉"
var notRequired = "○"

var stringToColour = function(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour+"75";
}

class FieldCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity: props.entity,
            field: props.field
        }
    }

    determineBackgroundColor(){
        if (this.state.field?.entity_ref){
            return stringToColour("gpg_"+this.state.entity.identifier+"_"+this.state.field.entity_ref)
        }   
        
        if (this.state.field?.type ==="array" &&
                 this.state.field?.array_config.entity_ref !== undefined &&
                  this.state.field?.array_config.entity_ref !== "") {
            return stringToColour("gpg_" + this.state.entity.identifier + "_" + this.state.field?.array_config.entity_ref)
        }

        if (this.state.field?.type === "json" && !this.state.field?.json_config.reuse) {
            for (let index = 0; index < this.state.field?.json_config.fields.length; index++) {
                const jf = this.state.field?.json_config.fields[index];
                if (jf.entity_ref !== undefined && jf.entity_ref !== "") { 
                    return stringToColour("gpg_" + this.state.entity.identifier + "_" + jf.entity_ref)
                }                  
            }           
        }

        return 'FEFEFE'
    }


    render() {
        return (
            <div 
                className={this.state.field.deprecated ? "deprecated fieldCard" : "fieldCard"} 
                style={{
                    backgroundColor:this.determineBackgroundColor()
                }} > 
                {this.state.field.storage_config?.primary_key ? 
                primaryKey : 
                (this.state.field.required ? required : notRequired)} 
                &nbsp;<b>{this.state.field.identifier}</b>&nbsp;
                {this.state.field.type}&nbsp;{this.state.field?.render.name ? '' : '(-r)'}
            </div>
        )
    }
}

export default FieldCard