
import './App.css';
import Project from './components/project';



function App() {  
  return (
    <div id="app" className="App" style={{display:"flex", width:"100%", height: "100%"}}>
       <Project />
    </div>
  );
}

export default App;
