import React from 'react'

var operations = ["select", "upsert", "delete"]
class FieldHidden extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            field: props.field,
            entity: props.entity,
            project: props.project,
        }
    }

    render() {
        return (
            <div style={{ padding: "10px 20px" }}>
                <b style={{ fontSize: "20px" }}>Hidden</b>
                <br /><br />
                <p>API</p>
                {
                    operations.map((operation, i) =>
                        <div key={"api-"+i}>
                            <input  type="checkbox" name={operation}
                                checked={this.state.field.hidden?.api?.filter((el) => el === operation).length > 0 ? "checked" : ""}
                                onChange={(e) => {
                                    this.setState((cs)=>{
                                        if (e.target.checked) {
                                            cs.field.hidden?.api.push(operation)
                                        } else {
                                            cs.field.hidden.api = cs.field.hidden?.api?.filter((el) => el !== operation)
                                        }
                                        return cs
                                    })                                    
                                }} />
                            &nbsp;
                            {operation}
                        </div>
                    )
                }
                <p>Admin</p>
                {
                    operations.map((operation, i) =>
                        <div key={"admin-"+i}>
                            <input type="checkbox" name={operation}
                                checked={this.state.field.hidden?.admin?.filter((el) => el === operation).length > 0 ? "checked" : ""}
                                onChange={(e) => {
                                    this.setState((cs)=>{
                                        if (e.target.checked) {
                                            cs.field.hidden?.admin.push(operation)
                                        } else {
                                            cs.field.hidden.admin = cs.field.hidden?.admin?.filter((el) => el !== operation)
                                        }
                                        return cs
                                    })                                                                       
                                }} />
                            &nbsp;
                            {operation}
                        </div>
                    )
                }
            </div>
        )
    }
}

export default FieldHidden