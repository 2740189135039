import React from 'react'
import APIConfig from './apiConfig'
import AuthConfig from './authConfig'
import DatabaseConfig from './databaseConfig'
import AwsConfig from './awsConfig'
import EventsConfig from './eventsConfig'

class RenderEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: props.type,
            project: props.project,
            entity: props.entity,
            field: props.field,
        }

        switch (props.type) {
            case "project":
                if (!this.state.project.render) {
                    this.state.project.render = {
                        name: "",
                        description: ""
                    }
                }
                this.state.render = this.state.project.render
                break
            case "entity":
                if (!this.state.entity.render) {
                    this.state.entity.render = {
                        name: "",
                        description: ""
                    }
                }
                this.state.render = this.state.entity.render
                break
            case "field":
                if (!this.state.field.render) {
                    this.state.field.render = {
                        name: "",
                        description: "",
                        field_type:""
                    }
                }
                this.state.render = this.state.field.render
                break
            default:
                break
        }
    }

    render() {
        return (
            <div style={{ padding: "10px 20px" }}>
                <span className='render_identifier'>{this.state.field?.identifier}</span>
                <br />
                {this.state.type === "project" ?
                    <div>
                        <b style={{ fontSize: "20px" }}>Configuration</b>
                        <br /><br />
                        <div style={{ display: "block", padding: "5px" }}>
                            <span className='inputLabel'>Identifier</span>
                            &nbsp;
                            <input
                                onChange={(e) => {
                                    this.setState((currState) => {
                                        currState.project.identifier = e.target.value
                                        return currState
                                    })
                                }}
                                type="text"
                                id="identifier"
                                name="identifier"
                                value={this.state.project?.identifier}
                                required />
                        </div>
                        <br />
                        <DatabaseConfig project={this.state.project} />
                        <br />
                        <AuthConfig project={this.state.project} />
                        <br />
                        <APIConfig project={this.state.project} />
                        <br />
                        <EventsConfig project={this.state.project} />
                        <br />
                        <AwsConfig project={this.state.project} />
                        <br />
                        <br />
                    </div>
                    : ''}
                {this.state.type === "entity" ?
                    <div>
                        <b style={{ fontSize: "20px" }}>Configuration</b>
                        <br /><br />
                        <div style={{ display: "block", padding: "5px" }}>
                            <span className='inputLabel'>Identifier</span>
                            &nbsp;
                            <input
                                onChange={(e) => {
                                    this.setState((currState) => {
                                        currState.entity.identifier = e.target.value
                                        return currState
                                    })
                                }}
                                type="text"
                                id="identifier"
                                name="identifier"
                                value={this.state.entity?.identifier}
                                required />
                        </div>
                        <br /><br />
                    </div>
                    : ''}
                <b style={{ fontSize: "20px" }}>Render</b>
                <br /><br />
                <div style={{ display: "block", padding: "5px" }}>
                    <span className='inputLabel'>Name</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.render.name = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="name"
                        name="name"
                        value={this.state.render?.name}
                        required />
                    <br />
                </div>
                <div style={{ display: "block", padding: "5px" }}>
                    <span className='inputLabel'>Description</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.render.description = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="description"
                        name="description"
                        value={this.state.render?.description}
                        required />
                </div>
                {this.state.type === "field" ?
                    <div style={{ display: "block", padding: "5px" }}>
                        <span className='inputLabel'>Field Type</span>
                        &nbsp;
                        <select id="type"
                            name="type" value={this.state.render?.field_type}
                            onChange={(e) => {
                                this.setState((currState) => {
                                    currState.render.field_type = e.target.value
                                    return currState
                                })
                            }}
                        >
                            <option value=""></option>
                            <option value="image_upload">Image Upload</option>
                            <option value="logged_user_id">Logged User ID</option>
                        </select>
                    </div>
                    : ''}
            </div>
        )
    }
}

export default RenderEditor