/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

class Validation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            field: props.field,
            validation: props.validation
        }
    }

    render() {
        return (
            <div style={{display:"block", padding:"5px"}}>
                Operation:
                <select onChange={(e) => {
                    this.setState((cs)=>{
                        cs.validation.operation = e.target.value  
                        return cs
                    })                                     
                }}
                    value={this.state.validation?.operation}>
                    <option value="select">select</option>
                    <option value="upsert">upsert</option>
                    <option value="delete">delete</option>
                </select>
                &nbsp;
                Type:
                <select onChange={(e) => {
                    this.setState((cs)=>{
                        cs.validation.type = e.target.value  
                        return cs
                    })                    
                }}
                    value={this.state.validation?.type}>
                    <option value="rule">rule</option>                    
                </select>
                &nbsp; 
                Rule:
                <select onChange={(e) => {
                    this.setState((cs)=>{
                        cs.validation.rule = e.target.value  
                        return cs
                    })                                     
                }}
                    value={this.state.validation?.rule}>
                    <option value="not_nil">not_nil</option>                    
                    <option value="unique">unique</option>                    
                </select>
                &nbsp;   
                <a href='#' onClick={
                    () => {
                        this.setState((cs)=>{
                            var valId = this.state.validation.operation+this.state.validation.type+this.state.validation.rule
                            var filtered = this.state.field.state.field.validations.filter((el) => el.operation+el.type+el.rule !== valId); 
                            cs.field.state.field.validations = filtered                        
                            cs.field.setState(cs.field.state)   
                            return cs
                        })
                                                                                 
                    }
                }>Remove</a>
            </div>
        )
    }
}

export default Validation