import React from 'react'

const APIProtocol = {
    GraphQL: 'graphql',
    Protobuf: 'protobuf',
    All: 'all',
}

class APIConfig extends React.Component {
    constructor(props) {
        super(props)
        if (props.project.api === undefined) {
            props.project.api = {
                domain: "",
                protocol: "",
                httpport: "",
                grpcport: ""
            }
        }
        this.state = {
            project: props.project,
        }
    }

    render() {
        return (
            <div>
                <b style={{ fontSize: "20px" }}>API</b>
                <br /><br />
                <div style={{ display: "block", padding: "5px" }}>
                    <span className='inputLabel'>Domain</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.api.domain = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="api_domian"
                        name="api_domain"
                        value={this.state.project?.api?.domain}
                        required />
                    <br />
                    <span className='inputLabel'>Protocol</span>
                    &nbsp;
                    <select
                        className='select'
                        name="protocoltype"
                        value={this.state.project?.api?.protocol}
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.api.protocol = e.target.value
                                return currState
                            })
                        }}
                    >
                        <option value=""></option>
                        <option value={APIProtocol.All}>All</option>
                        <option value={APIProtocol.GraphQL}>GraphQL</option>
                        <option value={APIProtocol.Protobuf}>Protobuf</option>
                    </select>
                    <br />
                    <span className='inputLabel'>HTTP Port</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.api.httpport = e.target.value
                                return currState
                            })
                        }}
                        type="text"
                        id="api_http"
                        name="api_http"
                        value={this.state.project?.api?.httpport}
                        required />
                    <br />
                    {this.state.project?.api?.protocol === APIProtocol.Protobuf || this.state.project?.api?.protocol === APIProtocol.All ?
                        <span>
                            <span className='inputLabel'>GRPC Port</span>
                            &nbsp;
                            <input
                                onChange={(e) => {
                                    this.setState((currState) => {
                                        currState.project.api.grpcport = e.target.value
                                        return currState
                                    })
                                }}
                                type="text"
                                id="api_grpc"
                                name="api_grpc"
                                value={this.state.project?.api?.grpcport}
                                required />
                            <br />
                        </span>
                        : null}


                </div>
            </div>
        )
    }
}

export default APIConfig