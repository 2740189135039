import React from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'

const EventTransport = {
    Kafka: 'kafka',
}

class EventsConfig extends React.Component {
    constructor(props) {
        super(props)
        if (props.project.events === undefined) {
            props.project.events = {
                enabled: false,
                transport: "",
                transportconfig: {
                    kafka: {
                        version: "",
                        brokers: [],
                        topics: []
                    }
                },
                allentities: true,
                entityidentifiers: []
            }
        }
        this.state = {
            project: props.project,
        }
    }

    render() {
        var entityOptions = this.state.project.entities.map(e => {
            return { value: e.identifier, label: e.identifier }
        })
        return (
            <div>
                <b style={{ fontSize: "20px" }}>Events</b>
                <br /><br />
                <div style={{ display: "block", padding: "5px" }}>
                    <span>
                        <input
                            type="checkbox"
                            name="events_enabled"
                            checked={this.state.project.events.enabled === true ? "checked" : ""}
                            onChange={(e) => {
                                this.setState((currState) => {
                                    currState.project.events.enabled = !this.state.project.events.enabled
                                    return currState
                                })
                            }}
                            aria-label='Enabled'
                        />
                        <span style={{ float: 'left', paddingTop: '7px' }} className='inputLabel'>Enabled</span>
                    </span>
                    <br />
                    {this.state.project.events.enabled ?
                        <span>
                            <span className='inputLabel'>Transport</span>
                            &nbsp;
                            <select
                                className='select'
                                name="protocoltype"
                                value={this.state.project?.events?.transport}
                                onChange={(e) => {
                                    this.setState((currState) => {
                                        currState.project.events.transport = e.target.value
                                        return currState
                                    })
                                }}
                            >
                                <option value={EventTransport.Kafka}>Kafka</option>
                            </select>
                            <br />
                            <input
                                type="checkbox"
                                name="all_entities_enabled"
                                checked={this.state.project.events.allentities === true ? "checked" : ""}
                                onChange={(e) => {
                                    this.setState((currState) => {
                                        currState.project.events.allentities = !this.state.project.events.allentities
                                        return currState
                                    })
                                }}
                                aria-label='Enabled'
                            />
                            <span style={{ float: 'left', paddingTop: '7px' }} className='inputLabel'>All Entities</span>
                            <br />
                            {!this.state.project.events.allentities ?
                                <span>
                                    <span className='inputLabel'>Entity Identifiers</span>
                                    &nbsp;
                                    <Select
                                        isMulti
                                        className='select'
                                        name="entityidentifiers"
                                        isClearable
                                        value={this.selectValuesToOptions(this.state.project?.events?.entityidentifiers)}
                                        onChange={
                                            (newValue) => {
                                                this.setState((currState) => {
                                                    currState.project.events.entityidentifiers = this.selectOptionsToValues(newValue)
                                                    return currState
                                                })
                                            }
                                        }
                                        options={entityOptions}
                                    />
                                </span> : null}
                        </span>
                        : null}
                    <br />
                    {this.state.project?.events?.transport === EventTransport.Kafka ?
                        this.renderKafakConfig()
                        : null}


                </div>
            </div>
        )
    }

    renderKafakConfig() {
        return <div style={{ padding: '20px', border: '1px solid #CCC' }}>
            <b style={{ fontSize: "14px" }}>Configuration</b>
            <br />
            <span className='inputLabel'>Version</span>
            &nbsp;
            <input
                onChange={(e) => {
                    this.setState((currState) => {
                        currState.project.events.transportconfig.kafka.version = e.target.value
                        return currState
                    })
                }}
                type="text"
                id="api_grpc"
                name="api_grpc"
                value={this.state.project?.events?.transportconfig?.kafka?.version}
                required />
            <br />
            <span className='inputLabel'>Brokers</span>
            &nbsp;
            <CreatableSelect 
                isMulti
                className='select'
                name="brokers"                
                value={this.selectValuesToOptions(this.state.project?.events?.transportconfig?.kafka?.brokers)}
                onChange={
                    (newValue) => {
                        this.setState((currState) => {
                            currState.project.events.transportconfig.kafka.brokers = this.selectOptionsToValues(newValue)
                            return currState
                        })
                    }
                }                
            />
            <br/>
            <span className='inputLabel'>Topics</span>
            &nbsp;
            <CreatableSelect 
                isMulti
                className='select'
                name="topics"                
                value={this.selectValuesToOptions(this.state.project?.events?.transportconfig?.kafka?.topics)}
                onChange={
                    (newValue) => {
                        this.setState((currState) => {
                            currState.project.events.transportconfig.kafka.topics = this.selectOptionsToValues(newValue)
                            return currState
                        })
                    }
                }                
            />
            <br/>
        </div>
    }

    selectOptionsToValues(opts){        
        var vals = []
        if (!opts || opts.length === 0 ){
            return vals
        }
        opts.forEach(opt => {
            vals.push(opt.value)
        });
        return vals
    }

    selectValuesToOptions(vals){
        var opts = []
        if (!vals || vals.length === 0 ){
            return opts
        }
        vals.forEach(val => {
            opts.push({value:val,label:val})
        });
        return opts
    }
}




export default EventsConfig