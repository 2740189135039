import React from 'react'

class AwsConfig extends React.Component {
    constructor(props) {
        super(props)
        if (props.project.aws === undefined) {
            props.project.aws = {
                region: "",
                key: "",
                secret: "",
                bucket:""
            }
        }
        this.state = {            
            project: props.project,
        }
    }

    render() {
        return (
            <div>
                <b  style={{ fontSize: "20px" }}>AWS</b>
                <br /><br />
                <div style={{ display: "block", padding: "5px" }}>
                    <span className='inputLabel'>Region</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.aws.region = e.target.value
                                return currState
                            })                            
                        }}
                        type="text"
                        id="aws_region"
                        name="aws_region"
                        value={this.state.project?.aws?.region}
                        required />
                    <br />
                    <span className='inputLabel'>Key ID</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.aws.key = e.target.value
                                return currState
                            })                             
                        }}
                        type="text"
                        id="aws_key"
                        name="aws_key"
                        value={this.state.project?.aws?.key}
                        required />
                    <br />
                    <span className='inputLabel'>Secret</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.aws.secret = e.target.value
                                return currState
                            })                            
                        }}
                        type="text"
                        id="aws_secret"
                        name="aws_secret"
                        value={this.state.project?.aws?.secret}
                        required />                                        
                    <br />
                    <span className='inputLabel'>S3 Bucket</span>
                    &nbsp;
                    <input
                        onChange={(e) => {
                            this.setState((currState) => {
                                currState.project.aws.bucket = e.target.value
                                return currState
                            })                             
                        }}
                        type="text"
                        id="aws_bucket"
                        name="aws_bucket"
                        value={this.state.project?.aws?.bucket}
                        required />                                        
                    <br />
                </div>
            </div>
        )
    }
}

export default AwsConfig